import React from 'react';
// import FaceTracking from './TryOnGlasses';
import Glasses from './glasses/page';

function App() {
  return (
    <div className="App">
      <div className="container">
        {/* <h1>Hello Mind AR J/TS!</h1> */}
        <Glasses />
      </div>
    </div>
  );
}

export default App;
